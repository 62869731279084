import { css } from '@emotion/css';

// eslint-disable-next-line import/prefer-default-export
export const positionStyles = {
  absolute: css`
    position: absolute;
  `,
  floatRight: css`
    float: right;
  `,
  relative: css`
    position: relative;
  `,
  bottom: (distance: string | number) => css`
    bottom: ${distance};
  `,
  left: (distance: string | number) => css`
    left: ${distance};
  `,
  right: (distance: string | number) => css`
    right: ${distance};
  `,
  top: (distance: string | number) => css`
    top: ${distance};
  `,
};
