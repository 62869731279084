import { css } from '@emotion/css';
import { lighten } from 'polished';
import { Theme } from '@emotion/react';

// eslint-disable-next-line import/prefer-default-export
export const themedFocusOutline = (theme: Theme) => css`
  &:focus {
    outline: 3px solid ${lighten(0.1, theme.primaryHighlight)};
    outline-offset: 5px;
  }
`;
