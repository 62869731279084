import { DependencyList, useCallback, useEffect } from 'react';
import useAsyncCallback, { AsyncCallbackState } from './useAsyncCallback';

export interface AsyncRetryState<T> extends AsyncCallbackState<T> {
  retry: () => void;
}

/**
 * Hook that encapsulates state around an asynchronous
 * task such as loading, error or success states.
 * In the event of an error, we also provide a retry
 * method to retry the originally provided task.
 */
export default function useAsyncRetry<Value>(
  task: () => Promise<Value>,
  deps: DependencyList = [],
): AsyncRetryState<Value> {
  const [state, run] = useAsyncCallback<Value>(task, deps);
  const { isLoading } = state;

  useEffect(() => {
    run();
  }, [run]);

  const retry = useCallback(async () => {
    if (!isLoading) {
      await run();
    }
  }, [isLoading, run]);

  return {
    ...state,
    retry,
  };
}
